<template>
  <div>
    <v-btn
      class="mr-2 px-1"
      x-small
      :disabled="[
        statuses.ORDER_COMPLETED,
        statuses.ORDER_DISPATCHED,
        statuses.ORDER_READY_FOR_COLLECTION,
        statuses.ORDER_VOID,
        statuses.ORDER_INPROGRESS,
        statuses.ORDER_DRAFT
      ].includes(orderStatus) || loading"
      color="success"
      @click="changeStatus('ORDER_VOID')"
    >Void</v-btn>
    <v-btn
      class="mr-2 px-1"
      x-small
      :disabled="[
        statuses.ORDER_COMPLETED,
        statuses.ORDER_DISPATCHED,
        statuses.ORDER_READY_FOR_COLLECTION,
        statuses.ORDER_VOID,
        statuses.ORDER_ACCEPTED,
        statuses.ORDER_INPROGRESS,
        statuses.ORDER_DRAFT
      ].includes(orderStatus) || loading"
      color="success"
      @click="changeStatus('ORDER_ACCEPTED')"
    >Accept</v-btn>
    <v-btn
      class="mr-2 px-1"
      x-small
      :disabled="[
        statuses.ORDER_COMPLETED,
        statuses.ORDER_DISPATCHED,
        statuses.ORDER_READY_FOR_COLLECTION,
        statuses.ORDER_VOID,
        statuses.ORDER_INPROGRESS,
        statuses.ORDER_PENDING,
        statuses.ORDER_DRAFT
      ].includes(orderStatus) || loading"
      color="success"
      @click="changeStatus('ORDER_INPROGRESS')"
    >In Progress</v-btn>
    <v-btn
      v-if="orderDelivery === 'COLLECT'"
      class="mr-2 px-1"
      x-small
      :disabled="[
        statuses.ORDER_COMPLETED,
        statuses.ORDER_DISPATCHED,
        statuses.ORDER_READY_FOR_COLLECTION,
        statuses.ORDER_VOID,
        statuses.ORDER_PENDING,
        statuses.ORDER_ACCEPTED,
        statuses.ORDER_DRAFT
      ].includes(orderStatus) || loading"
      color="success"
      @click="changeStatus('ORDER_READY_FOR_COLLECTION')"
    >Ready For Collection</v-btn>
    <v-btn
      v-if="orderDelivery === 'COURIER'"
      class="mr-2 px-1"
      x-small
      :disabled="[
        statuses.ORDER_COMPLETED,
        statuses.ORDER_DISPATCHED,
        statuses.ORDER_READY_FOR_COLLECTION,
        statuses.ORDER_VOID,
        statuses.ORDER_PENDING,
        statuses.ORDER_ACCEPTED,
        statuses.ORDER_DRAFT
      ].includes(orderStatus) || loading"
      color="success"
      @click="changeStatus('ORDER_DISPATCHED')"
    >Dispatched</v-btn>
    <confirm-dialog ref="confirmDialog" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  props: {
    orderDelivery: String,
    orderStatus: String,
    statuses: Object,
    orderId: String,
    loading: Boolean
  },
  components: {
    ConfirmDialog
  },
  data () {
    return {
      statusMessages: {
        ORDER_VOID: 'Orders cannot be reactivated once set to VOID',
        ORDER_DISPATCHED: 'This will trigger a notification to the customer. Only change status to DISPATCHED when the order has been shipped.',
        ORDER_READY_FOR_COLLECTION: 'This will trigger a notification to the customer. Only change status when the order is ready.',
        ORDER_ACCEPTED: 'This will create a DRAFT Xero invoice and trigger a notification to the customer.',
        ORDER_INPROGRESS: 'Mark order as "in progress" - for internal info only'
      }
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  computed: {
  },
  methods: {
    async changeStatus (status) {
      if (
        await this.$refs.confirmDialog.open(
          `Change this order's status to ${this.statuses[status]}?`,
          this.statusMessages[status]
        )
      ) {
        this.$emit('changeStatus', { id: this.orderId, status: status })
      }
    }
  }
}
</script>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mr-2 px-1",attrs:{"x-small":"","disabled":[
      _vm.statuses.ORDER_COMPLETED,
      _vm.statuses.ORDER_DISPATCHED,
      _vm.statuses.ORDER_READY_FOR_COLLECTION,
      _vm.statuses.ORDER_VOID,
      _vm.statuses.ORDER_INPROGRESS,
      _vm.statuses.ORDER_DRAFT
    ].includes(_vm.orderStatus) || _vm.loading,"color":"success"},on:{"click":function($event){return _vm.changeStatus('ORDER_VOID')}}},[_vm._v("Void")]),_c('v-btn',{staticClass:"mr-2 px-1",attrs:{"x-small":"","disabled":[
      _vm.statuses.ORDER_COMPLETED,
      _vm.statuses.ORDER_DISPATCHED,
      _vm.statuses.ORDER_READY_FOR_COLLECTION,
      _vm.statuses.ORDER_VOID,
      _vm.statuses.ORDER_ACCEPTED,
      _vm.statuses.ORDER_INPROGRESS,
      _vm.statuses.ORDER_DRAFT
    ].includes(_vm.orderStatus) || _vm.loading,"color":"success"},on:{"click":function($event){return _vm.changeStatus('ORDER_ACCEPTED')}}},[_vm._v("Accept")]),_c('v-btn',{staticClass:"mr-2 px-1",attrs:{"x-small":"","disabled":[
      _vm.statuses.ORDER_COMPLETED,
      _vm.statuses.ORDER_DISPATCHED,
      _vm.statuses.ORDER_READY_FOR_COLLECTION,
      _vm.statuses.ORDER_VOID,
      _vm.statuses.ORDER_INPROGRESS,
      _vm.statuses.ORDER_PENDING,
      _vm.statuses.ORDER_DRAFT
    ].includes(_vm.orderStatus) || _vm.loading,"color":"success"},on:{"click":function($event){return _vm.changeStatus('ORDER_INPROGRESS')}}},[_vm._v("In Progress")]),(_vm.orderDelivery === 'COLLECT')?_c('v-btn',{staticClass:"mr-2 px-1",attrs:{"x-small":"","disabled":[
      _vm.statuses.ORDER_COMPLETED,
      _vm.statuses.ORDER_DISPATCHED,
      _vm.statuses.ORDER_READY_FOR_COLLECTION,
      _vm.statuses.ORDER_VOID,
      _vm.statuses.ORDER_PENDING,
      _vm.statuses.ORDER_ACCEPTED,
      _vm.statuses.ORDER_DRAFT
    ].includes(_vm.orderStatus) || _vm.loading,"color":"success"},on:{"click":function($event){return _vm.changeStatus('ORDER_READY_FOR_COLLECTION')}}},[_vm._v("Ready For Collection")]):_vm._e(),(_vm.orderDelivery === 'COURIER')?_c('v-btn',{staticClass:"mr-2 px-1",attrs:{"x-small":"","disabled":[
      _vm.statuses.ORDER_COMPLETED,
      _vm.statuses.ORDER_DISPATCHED,
      _vm.statuses.ORDER_READY_FOR_COLLECTION,
      _vm.statuses.ORDER_VOID,
      _vm.statuses.ORDER_PENDING,
      _vm.statuses.ORDER_ACCEPTED,
      _vm.statuses.ORDER_DRAFT
    ].includes(_vm.orderStatus) || _vm.loading,"color":"success"},on:{"click":function($event){return _vm.changeStatus('ORDER_DISPATCHED')}}},[_vm._v("Dispatched")]):_vm._e(),_c('confirm-dialog',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }